import bank from './assets/icons/bank.svg'
import bankWhite from './assets/icons/bank-white.svg'
import people from './assets/icons/people.svg'
import peopleWhite from './assets/icons/people-white.svg'
import legacy from './assets/icons/pc.svg'
import legacyWhite from './assets/icons/legacy-white.svg'
import debit from './assets/icons/debit.svg'
import crm from './assets/icons/crm.svg'
import internet from './assets/icons/internet-bank.svg'
import pg from './assets/icons/pg.svg'
import pgWhite from './assets/icons/pay-white.svg'
import wallet from './assets/icons/wallet.svg'

export const getConfig = async () => {
    const response = await fetch('./wow.config.json')
    const configs = await response.json()
    const updatedConfigs = configs.map((config) => {
        switch (config.id) {
            case 'core':
                const coreProd = getProductConfig(config.products)
                return {
                    ...config,
                    products: coreProd,
                    img: bank,
                    imgHover: bankWhite
                }
            case 'customer-service':
                const csrProd = getProductConfig(config.products)
                return {
                    ...config,
                    products: csrProd,
                    img: people,
                    imgHover: peopleWhite
                }
            case 'legacy':
                const legProd = getProductConfig(config.products)
                return {
                    ...config,
                    products: legProd,
                    img: legacy,
                    imgHover: legacyWhite
                }
            case 'pay':
                const payProd = getProductConfig(config.products)
                return {
                    ...config,
                    products: payProd,
                    img: debit,
                    imgHover: pgWhite
                }
            default:
                return {}
        }
    })

    console.log(updatedConfigs, 'con')

    return updatedConfigs
}

const getProductConfig = (products) => {
    return products.map((product) => {
        switch (product.id) {
            case 'credit':
                return {
                    ...product,
                    img: debit
                }
            case 'debit':
                return {
                    ...product,
                    img: debit
                }
            case 'cbs':
                return {
                    ...product,
                    img: bank
                }
            case 'acq':
                return {
                    ...product,
                    img: bank
                }
            case 'crm':
                return {
                    ...product,
                    img: crm
                }
                
            case 'internet-banking':
                return {
                    ...product,
                    img: internet
                }
            case 'prepaid':
                return {
                    ...product,
                    img: wallet
                }
            case 'pg':
                return {
                    ...product,
                    img: pg
                }
            case 'leg-acq':
                return {
                    ...product,
                    img: bank
                }
                
            case 'leg-debit':
                return {
                    ...product,
                    img: debit
                }
                
            case 'leg-credit':
                return {
                    ...product,
                    img: debit
                }
                
            default:
                return product  

        }
    })
}
