import Header from './Header'
import Products from './Products'
import inverted from '../../assets/icons/inverted-polygon.svg'
import outverted from '../../assets/icons/outverted-polygon.svg'
import '../../css/style-v2.css';

export default function Dashboard() {
    return (
        <main className='main'>
            <div className="dashboard">
                <section>
                    <Header />
                    <Products />
                </section>
                <img
                    className="logo logo--bottom"
                    src={outverted}
                    alt="wow bg logo"
                />
                <img
                    className="logo logo--top"
                    src={inverted}
                    alt="wow bg logo"
                />
                <footer>
                    <p>&copy; 2024 Wow Finstack</p>
                </footer>
            </div>
        </main>
    )
}
