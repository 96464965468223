import { useEffect, useState } from 'react'
import logo from '../../assets/logo.svg'
import { getConfig } from '../../config.old'
import '../../css/icon-styles.css'
import '../../css/index.css'
import inverted from '../../assets/icons/inverted-polygon.svg'
import outverted from '../../assets/icons/outverted-polygon.svg'

const Dashboard = () => {
    const [configurations, setConfigurations] = useState([])

    const doFetchConfig = async () => {
        const configs = await getConfig()
        setConfigurations(configs)
    }
    useEffect(() => {
        doFetchConfig()
    }, [])
    return (
        <section className="main-v1">
            <div className="main__content">
                <div className="header">
                    <img src={logo} alt="wow company symbol" />
                    <h4>A complete stack for scalable fintech</h4>
                </div>
                <div className="app-container">
                    {configurations.map((config) => (
                        <AppBox data={config} />
                    ))}
                </div>
            </div>
            <img
                className="logo logo--bottom"
                src={outverted}
                alt="wow bg logo"
            />
            <img className="logo logo--top" src={inverted} alt="wow bg logo" />
            <footer className='footer'>
                Copyright &copy; 2024 WOWFINSTACK PTE LTD.
            </footer>
        </section>
    )
}

const AppBox = ({ data }) => {
    const clName = `${data.icon} app-box__icon`
    return (
        <a
            href={data.link}
            className="app-box"
            target="_blank"
            rel="noreferrer"
        >
            <i className={clName} />
            <h3 className="app-box__title">{data.name}</h3>
        </a>
    )
}
export default Dashboard
