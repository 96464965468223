import React from 'react'
import logo from '../../assets/logo.svg'
export default function Header() {
  return (
    <header className='header'>
        <img src={logo}  alt='wow logo' className='header__img' />
        <h1 className='header__text'> A complete stack for scalable fintech</h1>
    </header>
  )
}
