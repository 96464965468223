export const getConfig = async () => {
    const response = await fetch('./wow.config.old.json')
    const config = await response.json()
    const configurations = [
        {
            icon: 'icon-basic-calendar',
            ...config.cbs
        },
        {
            icon: 'icon-basic-postcard',
            ...config.debit
        },
        {
            icon: 'icon-basic-postcard',
            ...config.credit
        },
        {
            icon: 'icon-basic-ipod',
            ...config.internetBanking
        },
        {
           icon: 'icon-basic-message-multiple',
           ...config.crm
        },
        {
            icon: 'icon-basic-webpage-multiple',
            ...config.multiCurrency
        },
        {
            icon: 'icon-basic-laptop',
            ...config.acquirer
        },
        {
            icon: 'icon-basic-smartphone',
            ...config.paymentGateway
        }, 
        {
            icon: 'icon-basic-server-cloud',
            ...config.wdAcq,
        },
        {
            icon: 'icon-basic-postcard',
            ...config.wdCredit
        }, 
        {
            icon: 'icon-basic-postcard',
            ...config.wdDebit
        }
    ]

    return configurations

}
