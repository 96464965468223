import React, { useState } from 'react'
import inverted from '../../assets/icons/inverted-polygon.svg'
import outverted from '../../assets/icons/outverted-polygon.svg'
import '../../css/style-v2.css';
import logo from '../../assets/logo.svg'


export default function Index() {
    const bcc = 'wowfinstacknepal@gmail.com';
    const [email, setEmail] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        const subject = "Complete Your Onboarding for Access to Our Demo Systems";
        const body = `Hello there,

Greetings from WOW Finstack.

Thank you for your interest in our systems! To help us set up your demo experience, please complete a quick onboarding form with some essential information.

Please click on the link to access the form and get started: http://demoaccess.wowfinstack.com:3003/

In the form, you'll be asked for your name, email address, username, and a password of your choice. This information will allow us to configure a personalized experience for you.

If you have any questions, please feel free to reach out. We look forward to providing you with a comprehensive demo!

Best regards,
WOW Finstack`;

        window.location.href = `mailto:${email}?bcc=${encodeURIComponent(bcc)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };


    return (
        <main className="main">
            <div className="dashboard">
                <section>
                    <header className="header">
                        <img src={logo} alt="wow logo" className="header__img" />
                        <h1 className="header__text">A complete stack for scalable fintech</h1>
                    </header>
                    <form onSubmit={handleClick} className="form-container">
                        <h2 className="form-title">Request a Demo</h2>
                        <div className="form-group">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                                className="form-input"
                            />
                            <button type="submit" className="form-button">
                                Contact us
                            </button>
                        </div>
                    </form>
                </section>
                <img className="logo logo--bottom" src={outverted} alt="wow bg logo" />
                <img className="logo logo--top" src={inverted} alt="wow bg logo" />
                <footer className="demofooter">
                    <p className='leftside'>&copy; 2024 Wow Finstack</p>
                    <a href="http://demoaccess.wowfinstack.com:3003/" className="rightside">
                        http://demoaccess.wowfinstack.com:3003/
                    </a>
                </footer>
            </div>
        </main>

    )
}
