import React, { useEffect, useState } from 'react'
import { getConfig } from '../../configuration'

    export default function Products() {
    const [configurations, setConfigurations] = useState([])
    const [productId, setProductId] = useState('core')

    const subProducts =
        configurations.find((config) => config.id === productId)?.products || []

    const doFetchConfig = async () => {
        const configs = await getConfig()
        setConfigurations(configs)
    }

    useEffect(() => {
        doFetchConfig()
    }, [])

    return (
        <div>
            <nav className="product-container">
                {configurations.map((config) => (
                    <ProductItem
                        id={config.id}
                        imgHover={config.imgHover}
                        selectedId={productId}
                        handleProductClick={() => setProductId(config.id)}
                        title={config.title}
                        img={config.img}
                    />
                ))}
            </nav>

            <ul className="sub-product-container">
                {subProducts.map((subProd) => (
                    <SubItem
                        title={subProd.name}
                        img={subProd.img}
                        desc={subProd.desc}
                        link={subProd.link}
                    />
                ))}
            </ul>
        </div>
    )
}

const ProductItem = ({ title, img, handleProductClick, selectedId, id, imgHover }) => {
    return (
        <button data-active={selectedId === id} className="product" onClick={handleProductClick}>
            <img src={selectedId === id ? imgHover : img} alt="bank icon" className="product__photo" />
            <h3 className="product__title">{title}</h3>
        </button>
    )
}

const SubItem = ({img, title, link}) => {
    return (
        <li className="sub-product">
            <a href={link}>
                <img className="sub-product__photo" src={img} alt="icon" />
                <div className="sub-product__text-container">
                    <h3 className="product__title">{title}</h3>
                    {/* <p className="sub-product__desc">
                        {desc}
                    </p> */}
                </div>
            </a>
        </li>
    )
}
