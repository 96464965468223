// import Dashboard from "./components/DashboardV2"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import DashboardV1 from "./components/Dashboard"
import DashboardV2 from "./components/DashboardV2"
import DashboardV3 from "./components/DashboardV3"
import Demo from "./components/Demo"

const App = () => {

    return <BrowserRouter>
        <Routes>
            <Route index element={<DashboardV1 />} />
            <Route path="v2" element={<DashboardV2 />} />
            <Route path="v3" element={<DashboardV3 />} />
            <Route path="demo" element={<Demo />} />
        </Routes>
    </BrowserRouter>
}

export default App